<template>
  <div>
    <!-- Toolbar -->
    <div class="d-flex toolbar justify-content-between mb-4">
      <h4 class="text-color-4">{{ $t("general.shop") }}</h4>

      <div class="d-flex">
        <TextBox type="text"
                 size="sm"
                 id="shop-search-keyword"
                 :placeholder="'ค้นหาร้าน'"
                 v-model="searchKeyword"
                 :input_wrapper_class="'mb-0 pb-0'"
                 :wrapper_class="'mb-0 pb-0 mr-3'" />

        <Button id="btn-add-shop"
                type="button"
                variant="success"
                data-toggle="modal"
                data-target="#shopModal"
                @click="handleAddForm()"
                size="sm">
          <i class="fal fa-plus-circle mr-1"></i>
          {{ $t("settings.shops.button.add_shop") }}
        </Button>
      </div>
    </div>

    <!-- Shops -->
    <div v-if="shops">
      <div v-if="shops.length">
        <div v-for="(shop, index) in cShops"
             :key="index">
          <div class="shop-details mb-2 mb-md-4"
               id="'shop-details-' + index">
            <div class="toolbar d-flex justify-content-between">
              <h4 class="text-color-2">
                <button class="btn btn-default"
                        v-if="shop.collapsed"
                        @click="() => (shop.collapsed = false)">
                  <i class="fal fa-chevron-down"></i>
                </button>
                <button class="btn btn-default"
                        v-else
                        @click="() => (shop.collapsed = true)">
                  <i class="fal fa-chevron-up"></i>
                </button>

                {{ shop.details.name }}
                <br />
                <span style="font-size: 0.5rem">{{ shop.id }}</span>
                <button class="btn btn-default btn-sm"
                        v-clipboard:copy="shop.id"
                        v-clipboard:success="() => copyHandler('success')"
                        v-clipboard:error="() => copyHandler('danger')">
                  <i class="fal fa-copy"></i>
                </button>
              </h4>
              <span v-if="shop.details.defaultWeight"
                    class="text-color-4">
                ({{ $t("settings.shops.default_weight") }}:
                <span class="text-color-5">{{ shop.details.defaultWeight }}gm)</span>
              </span>
              <div>
                <Button custom_class="mr-2 text-primary"
                        @click="editItem(shop)"
                        variant="default"
                        size="sm">
                  <i class="fal fa-pen"></i>
                </Button>
                <Button type="button"
                        @click="deleteItem(shop)"
                        variant="default"
                        size="sm"
                        custom_class="text-danger">
                  <i class="fal fa-trash-alt"></i>
                </Button>
              </div>
            </div>
            <div class="mt-4"
                 v-if="!shop.collapsed">
              <div class="row">
                <div class="col">
                  <label class="text-color-5">{{
                    $t("general.address.district")
                    }}</label>
                  <p class="text-color-4">
                    {{
                      shop.details.address
                        ? shop.details.address.district
                    : "N/A"
                    }}
                  </p>
                  <br />

                  <label class="text-color-5">{{
                    $t("general.address.zipcode")
                    }}</label>
                  <p class="text-color-4">
                    {{
                      shop.details.address
                        ? shop.details.address.zipcode
                    : "N/A"
                    }}
                  </p>
                </div>

                <div class="col">
                  <label class="text-color-5">{{
                    $t("general.address.address")
                    }}</label>
                  <p class="text-color-4">
                    {{
                      shop.details.address
                        ? shop.details.address.address
                    : "N/A"
                    }}
                  </p>
                  <br />
                  <label class="text-color-5">{{ $t("general.phone") }}</label>
                  <br />
                  <p class="text-color-4">{{ shop.details.phoneNo }}</p>
                </div>

                <div class="col">
                  <label class="text-color-5">{{
                    $t("general.address.sub_district")
                    }}</label>
                  <p class="text-color-4">
                    {{
                      shop.details.address
                        ? shop.details.address.subDistrict
                    : "N/A"
                    }}
                  </p>
                  <br />
                  <label class="text-color-5">{{
                    $t("general.address.province")
                    }}</label>
                  <p class="text-color-4">
                    {{
                      shop.details.address
                        ? shop.details.address.province
                    : "N/A"
                    }}
                  </p>
                </div>

                <div class="col">
                  <label class="text-color-5">{{
                    $t("shipment.view.contact_information.shipper")
                    }}</label>
                  <p class="text-color-4">
                    {{
                      shop.details.shippingMethods
                        ? shop.details.shippingMethods.join(", ")
                    : ""
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="alert alert-warning mt-5"
             role="alert">
          <i class="fa fa-exclamation-triangle mr-2"
             aria-hidden="true"></i>
          <span class="text-color-4">{{ $t("settings.shops.not_found") }}</span>
        </div>
      </div>
    </div>
    <div v-else>
      <Spinner size="sm" />
    </div>

    <Modal class="shopModal"
           id="shopModal"
           v-if="shopForm">
      <template slot="header">
        <h5 class="modal-title"
            id="addShopModalLabel">
          <i class="mr-2 fal fa-store-alt"></i>
          <span v-if="formMode === 0">{{
            $t("settings.shops.button.add_shop")
            }}</span>
          <span v-else-if="formMode === 1">{{
            $t("settings.shops.button.update_shop")
            }}</span>
        </h5>
      </template>

      <template slot="body">
        <ValidationObserver v-slot="{ invalid }">
          <form ref="shopForm"
                @submit.prevent="handleFormSubmit">
            <!-- Shop Name && Phone Number -->
            <div class="row">
              <div class="col-md-6 sol-sm-12">
                <TextBox type="text"
                         size="sm"
                         id="shop-name"
                         :label="$t('general.name')"
                         :placeholder="$t('general.name')"
                         rules="required"
                         v-model="shopForm.name" />
              </div>

              <div class="col-md-6 sol-sm-12">
                <PhoneNumber :label="$t('general.phone')"
                             :placeholder="$t('general.phone')"
                             id="shop-phone-number"
                             rules="required"
                             :value="shopForm.phoneNo"
                             v-model="shopForm.phoneNo" />
              </div>
            </div>

            <div class="row border-bottom mb-4">
              <div class="col">
                <NumberField id="weight-approx"
                             :label="$t('settings.shops.default_weight_gm')"
                             v-model.number="shopForm.defaultWeight" />
              </div>
              <div class="col">
                <validation-provider rules="wallletId">
                  <WalletId :label="$t('settings.shops.wallet_id')"
                            :placeholder="$t('settings.shops.wallet_id')"
                            id="shop-wallet-id"
                            :value="shopForm.walletId"
                            v-model="shopForm.walletId" />
                </validation-provider>
              </div>
            </div>

            <!-- Shipping Address && Zip Code -->
            <div class="row border-bottom pb-4 mb-4"
                 v-if="shopForm.shippingMethods">
              <div class="col-md-12 col-sm-12">
                <validation-provider rules="required">
                  <SmartAddress :label="$t('general.address.address')"
                                id="shipping-address"
                                :value="shopForm.address"
                                v-model="shopForm.address"
                                :key="componentKey"
                                rows="3"
                                :sm="shopForm.shippingMethods[0]" />
                </validation-provider>
              </div>
            </div>

            <div class="row border-bottom pb-2 mb-2">
              <div class="col">
                <label>{{ $t("shipment.edit.fields.shipping_methods") }}</label>

                <validation-provider rules="required">
                  <ShippingMethodInput :options="shippingMethods"
                                       :values="shopForm.shippingMethods"
                                       v-model="shopForm.shippingMethods"
                                       variant="info"
                                       size="sm"
                                       wrapper_class="mt-2 mb-2" />
                </validation-provider>
              </div>
            </div>



            <div class="d-flex jsutify-content-between">
              <label class="mt-4 mb-1">Onechat settings</label>
            </div>


            <div class="row mb-4 border p-4 mx-1 mt-0">
              <div class="col">
                <Toggle id="is-onechat-connected"
                        :value="shopForm.onechat.enabled"
                        variant="primary"
                        v-model="shopForm.onechat.enabled"
                        :labels="{
                          true: $t('settings.shops.enable_onechat'),
                          false: $t('settings.shops.disable_onechat'),
                        }" />

                <div v-if="shopForm.onechat.enabled"
                     class="mt-2 border-bottom mb-4 pb-4">

                  <FbIdInput v-model="shopForm.fbPageId"
                             :value="shopForm.fbPageId" />
                  <!-- <TextBox type="text"
                           size="sm"
                           id="shop-name"
                           :placeholder="'Fb page ID'"
                           :input_wrapper_class="'mb-0 pb-0'"
                           v-model="shopForm.fbPageId" /> -->

                  <input type="radio"
                         id="original"
                         value="created"
                         v-model="shopForm.onechat.status"
                         class="mr-1"
                         :checked="shopForm.onechat.status" />
                  <label for="original"
                         class="mr-2">{{
                          $t("settings.shops.onechat_option_order_creation")
                    }}</label>
                  <br />
                  <input type="radio"
                         id="status-2"
                         value="status-2"
                         v-model="shopForm.onechat.status"
                         class="mr-1"
                         :checked="shopForm.onechat.status" />
                  <label for="status-2"
                         class="mr-2">
                    ส่งเมื่อร้านค้าเปลี่ยนสถานะรายการเป็นพร้อมส่ง
                  </label>
                </div>
                <!-- Cancel onechat notification -->
                <Toggle id="onechat-cancelled"
                        :value="shopForm.onechat.cancelled"
                        variant="primary"
                        v-model="shopForm.onechat.cancelled"
                        :labels="{
                          true: 'ส่งข้อความหาลูกค้าเมื่อยกเลิกรายการ',
                          false: 'ส่งข้อความหาลูกค้าเมื่อยกเลิกรายการ',
                        }" />
              </div>
            </div>

            <!-- <div class="row">
              <div class="col">
                <label>Payment methods</label>

                <validation-provider rules="required">
                  <BankSelectInput
                    multiple
                    :options="bankAccounts"
                    v-model="shopForm.paymentMethods"
                    :values="shopForm.paymentMethods"
                  />
                </validation-provider>
              </div>
            </div>-->

            <div class="d-flex justify-content-center">
              <Button custom_class="pl-4 pr-4 btn-block"
                      type="submit"
                      id="btn-add-update-shop"
                      size="md"
                      :disabled="invalid"
                      variant="primary"
                      :loading="formLoading">
                <span v-if="formMode === 0">{{
                  $t("settings.shops.button.add_shop")
                }}</span>
                <span v-if="formMode === 1">{{
                  $t("settings.shops.button.update_shop")
                }}</span>
              </Button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
  </div>
</template>

<script>
import Card from "@/components/core/Card";
import Button from "@/components/core/Button";
import TextBox from "@/components/core/TextBox";
import PhoneNumber from "@/components/derived/PhoneNumber";
import WalletId from "@/components/derived/WalletId";
import ShippingMethodInput from "@/components/derived/ShippingMethodInput";
import Modal from "@/components/core/Modal";
import NumberField from "@/components/core/NumberField";
import SmartAddress from "@/components/derived/SmartAddress";
import Spinner from "@/components/core/Spinner";
import Toggle from "@/components/core/Toggle";
import { Alert } from "@/helpers/alert";
import { mapGetters } from "vuex";
import { bankInfo } from "@/helpers/core";
import { shippingInfo } from "@/helpers/core";
import { Toast } from "@/helpers/toastr";
import { i18n } from "@/i18n";
import FbIdInput from "../../../components/derived/FbIdInput.vue";

export default {
  name: "SellerSettingsShops",
  components: {
    Card,
    Button,
    TextBox,
    PhoneNumber,
    WalletId,
    Modal,
    SmartAddress,
    ShippingMethodInput,
    Spinner,
    NumberField,
    Toggle,
    FbIdInput
  },

  data() {
    return {
      componentKey: 0,
      formLoading: false,
      activeShopId: null,
      shops: null,
      formMode: 0, //0: create, 1: edit
      shopForm: null,
      banks: [],
      bankAccounts: {},
      shippingMethods: [],
      searchKeyword: null,
    };
  },

  mounted() {
    this.fetchShipping(() => {
      this.fetchShops();
      this.initForm();
    });
  },

  methods: {
    copyHandler(type) {
      let message = "Copied to Clipboard";
      if (type === "danger") message = "Error copying";
      Toast(message, type);
    },

    fetchShops() {
      this.shops = null;
      this.$store.dispatch("shops/fetchShops", {
        query: "responseSize=all",
        callback: (status, data) => {
          if (status) {
            this.shops = data
              .sort((a, b) => {
                return b.updatedAt - a.updatedAt;
              })
              .map((shop, i) => {
                if (!shop.details.onechat)
                  shop.details.onechat = {
                    enabled: false,
                    status: null,
                    cancelled: true,
                  };

                // Cancelled is default ON
                if (
                  shop.details.onechat &&
                  !("cancelled" in shop.details.onechat)
                )
                  shop.details.onechat.cancelled = true;

                if (!shop.details.hasOwnProperty("default"))
                  shop.details.default = false;

                shop.details.walletId = {
                  data: shop.details.walletId,
                  error: null,
                };

                shop.collapsed = true;
                return shop;
              });

            if (!this.shops.length) {
              this.handleAddForm();
              $("#shopModal").modal("show");
            }

            // open shop edit form
            const shopId = this.$route.query.shopId;
            const shopToEdit = this.shops.find(s => s.id === shopId);
            if (shopToEdit) this.editItem(shopToEdit);
          }
        },
      });
    },
    backGround(bank) {
      return "background-color:" + bankInfo(bank).color + ";";
    },

    initForm() {
      this.shopForm = {
        name: null,
        phoneNo: null,
        address: null,
        shippingMethods: [],
        defaultWeight: null,
        paymentMethods: [],
        walletId: null,
        onechat: {
          enabled: false,
          statusList: [],
        },
      };
    },

    fetchShipping(callback) {
      this.$store.dispatch("settings/fetchShippingMethods", {
        callback: (status, data) => {
          if (status) {
            if (callback) callback();
            if (!data || data.length === 0)
              data = ["kerry", "ems-cod", "self-ship", "ems"];
            data.forEach((method) => {
              this.shippingMethods.push(shippingInfo(method));
            });
            this.shopForm.shippingMethods = [this.shippingMethods[0].name];
          }
        },
      });
    },

    handleFormSubmit() {
      let valueToSend = JSON.parse(JSON.stringify(this.shopForm));

      if (valueToSend.walletId)
        valueToSend.walletId = valueToSend.walletId.data;
      else delete valueToSend.walletId;
      if (valueToSend.walletId === "") delete valueToSend.walletId;

      // Two sources of shippiing methods:
      // 1. Enabled
      // 2. Selected
      // Rule: 'selected' should exist on 'enabled'
      valueToSend.shippingMethods = valueToSend.shippingMethods.filter(sm => this.shippingMethods.map(_sm => _sm.name).includes(sm));

      if (this.formMode === 0) {
        this.formLoading = true;
        this.$store.dispatch("shops/createShop", {
          shop: valueToSend,
          callback: () => {
            this.initForm();
            this.formLoading = false;
            this.fetchShops();
            $("#shopModal").modal("hide");
          },
        });
      } else if (this.formMode === 1) {
        this.formLoading = true;
        this.$store.dispatch("shops/updateShop", {
          id: this.activeShopId,
          data: valueToSend,
          callback: () => {
            $("#shopModal").modal("hide");
            this.initForm();
            this.fetchShops();
            this.formLoading = false;
          },
        });
      }
    },

    markAsDefault(shop) {
      //mark selected shop as default
      shop.details.default = true;
      this.updateShopProgramatic(shop);

      //remove default from previous shop
      let prevDefaultShop = this.shops.find((s) => s.details.default === true);
      if (prevDefaultShop) {
        prevDefaultShop.details.default = false;
        this.updateShopProgramatic(prevDefaultShop);
      }
    },

    handleAddForm() {
      this.componentKey += 1;
      this.formMode = 0;
      this.initForm();
    },

    editItem(shop) {
      this.shopForm = null;
      this.shopForm = JSON.parse(JSON.stringify(shop)).details;
      this.activeShopId = shop.id;
      this.formMode = 1;
      $("#shopModal").modal("show");
    },

    updateShopProgramatic(shop) {
      this.editItem(shop);
      $("#shopModal").modal("hide");
      this.handleFormSubmit();
    },

    redirectToShipping() {
      $("#shopModal").modal("hide");
      this.$router.push({ name: "Seller-Settings-Shipping" });
    },

    deleteItem(shop) {
      Alert(
        i18n.t("general.delete"),
        i18n.t("settings.shops.delete_shop_message"),
        null,
        () => {
          this.shops = null;
          this.$store.dispatch("shops/deleteShop", {
            id: shop.id,
            callback: (status) => {
              this.fetchShops();
            },
          });
        }
      );
    },

    setFormMode(mode) {
      this.formMode = mode;
    },
  },

  computed: {
    cShops() {
      if (
        this.searchKeyword &&
        this.searchKeyword.length &&
        this.shops &&
        this.shops.length
      ) {
        return this.shops.filter((shop) => {
          if (shop && shop.details && shop.details.name) {
            let n = shop.details.name.toLowerCase();
            return n.includes(this.searchKeyword.toLowerCase());
          }
          return true;
        });
      }
      return this.shops;
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-details {
  background: $dashboard-background;
  padding: toRem(20px);
  border-radius: toRem(10px);
}

.payment-container {
  display: flex;
}

.payment-container>.payment-logo {
  height: 25px;
  width: 30px;
  border-radius: 5px;
  margin-right: 5px;
}

.shipping-warning:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>