<template>
  <div class="contacts-page">
    <div class="content-header mb-4">
      <h4 class="text-color-2">
        <span class="font-weight-light">ติดต่อเรา</span>
      </h4>
    </div>

    <div class="p-4"
         style="width: 700px">
      <Card>
        <div slot="body">
          <p class="lead mb-5">Proship Thailand</p>

          <div class="d-flex flex-row mb-4">
            <i class="fal fa-envelope mr-3"
               style="font-size: 1.8rem"></i>
            <p class="align-self-center">
              Email : <a href="mailto:care@proship.co.th">care@proship.co.th</a>
            </p>
          </div>

          <div class="d-flex flex-row mb-4">
            <i class="fal fa-phone mr-3"
               style="font-size: 1.8rem"></i>
            <p class="align-self-center">
              Tel : <a href="tel:+065-986-5752">065-986-5752</a>
            </p>
          </div>

          <div class="d-flex flex-row mb-4">
            <i class="fal fa-map-marker-alt mr-3 align-self-center"
               style="font-size: 1.8rem"></i>
            <div class="d-flex flex-column">
              <p class="mb-2"
                 style="font-weight: bold;">บริษัท โปรชิป เอ็กซ์เพรส จำกัด</p>
              <p style="width: 400px;">
                111 ทรูดิจิตัล พาร์ค เวสต์ ถนนสุขุมวิท แขวงบางจาก เขตพระโขนง กรุงเทพมหานคร 10260
              </p>
            </div>
          </div>

          <a href="https://line.me/R/ti/p/%40proship.co.th"
             target="_blank"
             rel="noopener noreferrer">
            <img src="@/assets/images/line.jpg"
                 style="height: 3rem; width: auto;" />
          </a>

        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from "@/components/core/Card";

export default {
  name: "Contact",
  components: { Card },
};
</script>

<style lang="scss" scoped>
.contacts-page {
  margin: 35px;
}
</style>