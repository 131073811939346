<template>
  <div class="notifications-page">
    <div class="content-header mb-4">
      <h4 class="text-color-2">
        <span class="font-weight-light">{{ $t("general.all") }}</span>
        Notifications
      </h4>
    </div>

    <div class="p-4" style="max-width: 768px; max-height: 1000px; overflow: auto;">

      <div v-if="notifications && notifications.length">
        <Card v-for="(notif, index) of notifications"
              :key="index">
          <div slot="body">
            <div class="d-flex flex-column">
              <p class="lead mb-2">
                {{ notif.message }}
              </p>

              <div class="d-flex align-items-center justify-space-between">
                <span class="mr-auto">
                  {{ notif.createdAt | formatDateTime }}
                </span>

                <router-link v-if="notif.eType === 'order'"
                             style="color: black;"
                             :to="{ name: 'Seller-Order-Detail', params: { id: notif.eId } }"> Order <i
                     class="fas fa-external-link"></i>
                </router-link>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div v-else class="alert alert-warning"> No notifications found </div>
    </div>
  </div>
</template>

<script>
import ReportsTable from "@/components/derived/ReportsTable";
import Card from "@/components/core/Card";
import DataTable from "@/components/core/DataTable";
import Spinner from "@/components/core/Spinner";

export default {
  name: "ReportsList",
  components: { ReportsTable, Card, Spinner, DataTable },
  created() {
    this.fetchNotifications();
  },
  data() {
    return {
      loading: true,
      notifications: null
    };
  },

  methods: {
    fetchNotifications() {
      this.$store.dispatch("notifications/fetchNotifications", {
        callback: (status, data) => {
          if (status) {
            this.notifications = data;
          }
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.notifications-page {
  margin: 35px;
}
</style>