import Vue from 'vue'
import Vuex from 'vuex'
import DashboardModule from '@/store/modules/dashboard';
import OnboardModule from '@/store/modules/onboard';
import ProductsModule from '@/store/modules/products';
import CustomersModule from '@/store/modules/customers';
import OrdersModule from '@/store/modules/orders';
import SalesAgentModule from '@/store/modules/salesAgent';
import PaymentSlipModule from '@/store/modules/paymentSlip';
import PreShipmentsModule from '@/store/modules/preShipments';
import UtilModule from '@/store/modules/util';
import WalletModule from '@/store/modules/wallet';
import NotificationModule from '@/store/modules/notifications';

import ShopsModule from '@/store/modules/shops';
import SettingsModule from '@/store/modules/settings';
import UserModule from '@/store/modules/user';
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({ storage: window.localStorage })

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    dashboard: DashboardModule,
    products: ProductsModule,
    customers: CustomersModule,
    orders: OrdersModule,
    preShipments: PreShipmentsModule,
    onboard: OnboardModule,
    user: UserModule,
    salesAgent: SalesAgentModule,
    paymentSlip: PaymentSlipModule,
    util: UtilModule,
    wallet: WalletModule,
    notifications: NotificationModule,

    settings: SettingsModule,
    shops: ShopsModule
  },
  plugins: [vuexLocal.plugin]
})


