<template>
  <div>
    <div class="
            toolbar
            d-flex
            flex-wrap
            justify-content-center justify-content-md-between
            mb-2
          ">
      <div class="d-flex">
        <button v-if="showSelectAll"
                class="btn btn-outline-primary btn-sm mr-2"
                @click="selectAll()">
          <i class="fal fa-ballot-check mr-2"></i>
          {{ $t("shipment.buttons.select_all") }}
        </button>

        <button v-if="showSelectAll"
                class="btn btn-outline-primary btn-sm mr-2"
                @click="selectAll(true)">
          <i class="fal fa-ballot-check mr-2"></i>
          {{ $t("shipment.buttons.select_all_not_printed") }}
        </button>

        <button v-if="!sales_agent"
                class="btn btn-outline-primary btn-sm mr-2"
                data-toggle="modal"
                data-target="#orders-import-modal">
          <i class="fal fa-cloud-download mr-2"></i>
          {{ $t("general.import") }}
        </button>

        <button class="btn btn-outline-primary btn-sm mr-2"
                v-if="selectedOrders && selectedOrders.length > 0"
                data-toggle="modal"
                data-target="#orders-print-labels"
                @click="() => (printUrl = null)">
          <i class="fal fa-print mr-2"></i>
          {{ $t("shipment.buttons.print_label") }}
        </button>

        <button class="btn btn-outline-primary btn-sm"
                v-if="selectedOrders && selectedOrders.length > 0"
                data-toggle="modal"
                data-target="#orders-status-modal">
          <i class="fal fa-exchange mr-2"></i>
          {{ $t("shipment.buttons.change_status") }}
        </button>

        <!-- Show only error -->
        <button v-if="showSelectAll"
                class="btn btn-outline-danger btn-sm mr-2"
                @click="showOnlyErrorShipments()">
          <i class="fal fa-exclamation-triangle mr-2"></i>
          {{ $t("shipment.buttons.show_only_errors") }}
        </button>

        <!-- Return Processing -->
        <button v-if="currentTabStatus == 8"
                class="btn btn-outline-primary btn-sm mr-2"
                data-toggle="modal"
                data-target="#return-processing-modal">
          {{ $t("return_processing.button") }}
        </button>

        <!-- Shops Filter -->
        <select class="form-control form-control-sm align-self-center"
                v-model="selectedOrderShop"
                @change="handleShopFilterChange"
                :style="{ width: '8rem' }">
          <option :value="shop.id"
                  v-for="shop in orderShops"
                  :key="shop.id">
            {{ shop.name }}
          </option>
        </select>
      </div>

      <div class="d-flex flex-wrap justify-content-center justify-content-md-start">
        <div class="input-group align-self-center mr-2 mt-2 mt-md-0 mb-2 mb-md-0"
             style="width: 22rem">
          <select class="form-control form-control-sm"
                  v-model="search.column">
            <option value="trackingNo">{{ $t("search.tracking_no") }}</option>
            <option value="phoneNo">{{ $t("search.phone_no") }}</option>
            <option value="customerName">{{ $t("search.customer") }}</option>
            <option value="remarks">{{ $t("search.remarks") }}</option>
          </select>

          <input type="text"
                 class="form-control form-control-sm flex-1"
                 :placeholder="$t('search.search_keyword')"
                 v-model.trim="search.keyword"
                 style="width: 8rem"
                 @keydown.enter="searchOrders()" />
          <div class="input-group-append">
            <button class="btn btn-outline-success btn-sm"
                    type="button"
                    @click="searchOrders()">
              <i class="fal fa-search"></i>
            </button>
          </div>
        </div>

        <router-link v-if="!sales_agent"
                     :to="{ name: 'Seller-Order-Quick-Create' }"
                     class="btn btn-success btn-sm"
                     :class="{
                      'mt-2 mt-md-0': selectedOrders && selectedOrders.length > 0,
                    }"
                     style="width: 8rem">
          <i class="fal fa-plus-circle mr-2"></i>
          {{ $t("form.shipment.quick_create") }}
        </router-link>
      </div>
    </div>
    <!-- <div class="alert alert-danger my-3">
      ระบบนับตัวเลขกำลังทำการนับใหม่
      หากตัวเลขจำนวนออเดอร์ในสถานะไม่ตรงกับออเดอร์จริงที่มีอยู่
      ระบบจะทำการนับใหม่ในวันที่ 15.2.2023
    </div> -->

    <div class="order-status-list">
      <Tabs v-if="orderCounts"
            id="order-list-tabs"
            class="is-primary"
            :tabs="orderCounts"
            :tabClasses="[
              'default',
              'warning',
              'primary',
              'info',
              'danger',
              'success',
              'normal',
              'danger',
            ]"
            @tabSelected="handleTabChange"
            :active_tab_index="activetabIndex"
            :manualCron="true"
            :manualCronLoading="manualCronLoading"
            :manualCronLoadingStatus="manualCronLoadingStatus"
            @runCron="runManualCron" />
    </div>

    <slot name="top"></slot>

    <div v-if="paginatedOrders && !loading">
      <div v-if="paginatedOrders.length"
           class="table-responsive">
        <table class="table _datatable orders-table --very-small-height">
          <thead>
            <tr>
              <th>#</th>
              <th>วันที่</th>
              <th>เวลา</th>
              <th>{{ $t("general.sales_agent") }}</th>
              <th>{{ $t("shipment.table_labels.customer") }}</th>
              <th>{{ $t("general.phone") }}</th>
              <th>{{ $t("shipment.table_labels.status") }}</th>
              <th>{{ $t("shipment.table_labels.shipping") }}</th>
              <th>{{ $t("shipment.table_labels.tracking_no") }}</th>
              <th>
                COD
                <i class="fas fa-cog"
                   style="cursor: pointer"
                   @click="handleCodFilterTriggerBtnClick"></i>
              </th>
              <th>{{ $t("general.actions") }}</th>
            </tr>
          </thead>
          <tbody :class="{ '--loading': miniLoading }">
            <tr v-for="(row, index) in computedOrdersList"
                :key="index">
              <td class="--checkbox">
                <input v-if="currentTabStatus == 1 || currentTabStatus == 2"
                       type="checkbox"
                       :id="'order-checkbox-' + row.id"
                       :ref="'order-checkbox-' + row.id"
                       class="table-checkbox"
                       @change="() => toggleSelect(row, true)" />

                <i v-if="row.printed || row.printCount > 0"
                   title="Printed"
                   class="fal fa-print text-success ml-2"></i>
              </td>
              <td v-html="tdHtml('createdAtDate', row.createdAt)"></td>
              <td v-html="tdHtml('createdAtTime', row.createdAt)"></td>

              <td>{{ row.user }}</td>

              <td v-html="tdHtml('ellipsis', row.customerName)"
                  class="text-nowrap"></td>

              <td>{{ row.phoneNo }}</td>

              <td v-html="tdHtml('status', row.status)"
                  class="--width-md"></td>

              <td class="--width-md">
                {{ row.shippingMethod.replace("thaipost0", "eps") }}
              </td>

              <td>
                <span class="mr-1">
                  {{ row.trackingNo }}
                  <span v-if="row.trackingNo"
                        class="--text-vsm">📨</span>
                </span>

                <i class="fas fa-store text-color-3"
                   v-if="row.returnReason === 'shop'"></i>
                <i class="fas fa-user text-color-3"
                   v-else-if="row.returnReason === 'customer'"></i>
              </td>

              <td class="--width-md">
                {{ row.codAmount }}

                <i class="fas fa-check-circle text-success ml-1"
                   v-if="row.codPaid"></i>
              </td>

              <td>
                <span v-if="row.hasError"
                      class="text-danger mr-2"
                      :title="$t('warning.import_error_message')">
                  <i class="fal fa-exclamation-triangle"></i>
                </span>

                <Button variant="link"
                        size="sm"
                        custom_class="mr-2 p-0 text-color-5"
                        id="btn-action-show"
                        @click="goto('Edit', row.id)"
                        v-if="row.status === 5">
                  <i class="fal fa-redo"></i>
                </Button>

                <Button variant="link"
                        size="sm"
                        custom_class="mr-2 p-0 text-color-5"
                        id="btn-action-show"
                        @click="goto('Show', row.id, true)">
                  <i class="fal fa-eye"></i>
                </Button>

                <Button v-if="allowEditStatus.includes(row.status) &&
                  row.shippingMethod != 'kerry' &&
                  row.shippingMethod != 'jt'
                "
                        variant="link"
                        size="sm"
                        custom_class="mr-2 p-0 text-color-5"
                        id="btn-action-edit"
                        @click="goto('Edit', row.id)">
                  <i class="fal fa-edit"></i>
                </Button>

                <Button v-if="allowCancelStatus.includes(row.status)"
                        variant="link"
                        custom_class="p-0 text-color-5 mr-2"
                        size="sm"
                        id="btn-action-delete"
                        @click="deleteItem(row.id)">
                  <i class="fal fa-times-circle"></i>
                </Button>

                <Button variant="link"
                        size="sm"
                        custom_class="p-0 text-color-5"
                        id="btn-action-tax-invoice"
                        @click="goto('TaxInvoice', row.id)">
                  <i class="fal fa-file-invoice"></i>
                </Button>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="d-flex justify-content-between">
          <div>
            <select class="form-control"
                    v-model="perPage"
                    @change="handlePerPageChange()">
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="300">300</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
              <option value="2500">2500</option>
            </select>
          </div>

          <div class="d-flex">
            <p class="align-self-center mr-2 text-color-4">Page {{ page }}</p>

            <div class="btn-group"
                 role="group">
              <Button type="button"
                      variant="outline-primary"
                      size="lg"
                      id="btn-paginate-prev"
                      @click="paginate(false)"
                      :disabled="page === 1">
                <i class="fal fa-long-arrow-left"></i>
              </Button>

              <Button type="button"
                      variant="outline-primary"
                      size="lg"
                      id="btn-paginate-prev"
                      @click="paginate(true)"
                      :disabled="page === lastPage"
                      :loading="miniLoading">
                <i class="fal fa-long-arrow-right"></i>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="!paginatedOrders.length && search.keyword">
        <div class="alert alert-warning">
          ระบบค้นหาไม่พบข้อมูลที่คุณค้นหา ข้อมูลของคุณอาจผิดพลาด
          หรือระบบค้นหาอาจทำงานผิดพลาด
        </div>
      </div>
    </div>

    <div v-else>
      <Spinner :variant="color_scheme"
               size="md" />
    </div>

    <!-- Import file modal -->
    <Modal id="orders-import-modal"
           backdrop>
      <template slot="header">
        <h5 class="modal-title pt-0">
          <i class="fal fa-file-import mr-2"></i>
          {{ $t("alert.import.lead_text") }}
        </h5>
      </template>
      <template slot="body">
        <p class="lead text-center"
           v-if="importLoading">
          {{ $t("alert.import.loading_message") }}
        </p>

        <div v-else>
          <Select v-if="shops"
                  id="shops"
                  :label="$t('alert.import.select_shop')"
                  :options="shops"
                  v-model="selectedShop" />

          <div v-if="selectedShop">
            <DropZone @uploaded="importOrders" />

            <p class="text-center mt-2 --text-sm">
              <a :href="sampleFileLink"
                 target="_blank"
                 rel="noopener noreferrer">
                {{ $t("alert.import.sample_file_text") }}</a>
            </p>
          </div>
        </div>
      </template>
    </Modal>

    <!-- Print Label modal -->
    <Modal id="orders-print-labels"
           custom_dialog_class="modal-dialog-centered"
           :backdrop="true">
      <template slot="body"
                v-if="printLabels">

        <div class="d-flex justify-content-between mb-4">
          <p class="lead text-center align-self-center">

            <span v-if="printLabelLoading">
              กำลังพิมพ์ใบปะหน้าจำนวน {{ selectedOrders ? selectedOrders.length : 0 }} ใบ
            </span>

            <span v-else-if="printUrl">
              ใบปะหน้าพร้อมแล้ว {{ selectedOrders ? selectedOrders.length : 0 }} ใบ
            </span>

            <span v-else>
              {{ $t("shipment.shipping_label.message") }}
            </span>
          </p>

          <Button id="btn-close-print-dialog"
                  variant="default"
                  size="sm"
                  @click="cancelModal('orders-print-labels')"
                  :disabled="printLabelLoading">
            <i class="fa fa-times"></i>
          </Button>
        </div>

        <div class="alert alert-success mb-2"
             v-if="printLabelLoading"
             style="font-size: 1rem">
          {{ $t("print_dialog.generating_link") }}

          <div class="progress mt-2"
               style="height: 4px; background-color: white;">
            <div class="progress-bar bg-success"
                 role="progressbar"
                 :style="{ width: `${printLabelLoadingProgress}%` }"
                 :aria-valuenow="printLabelLoadingProgress"
                 aria-valuemin="0"
                 aria-valuemax="100"></div>
          </div>
        </div>

        <div class="text-center mb-2 mt-2"
             id="print-message"
             v-if="printUrl">
          <a :href="printUrl"
             target="_blank"
             rel="noopener noreferrer"
             class="btn btn-success mr-2"
             @click="addPrintIcon()">
            {{ $t("print_dialog.click_to_download") }}
          </a>
        </div>

        <div class="d-flex flex-column"
             v-else-if="!printLabelLoading && !printUrl">
          <!-- Paper size selector -->
          <div class="flex-grow-1">
            <label>โปรดเลือกขนาดกระดาษที่ต้องการใช้งาน</label>
            <select class="form-control form-control-sm"
                    v-model="selectedPaperSize"
                    @change="setPaperSize">
              <option :value="paperSize"
                      v-for="paperSize in Object.keys(printLabels.paperSizes)"
                      :key="paperSize">
                {{ paperSize }}
              </option>
            </select>
          </div>

          <!-- search-by-products -->
          <div class="mt-1 d-flex">
            <input type="checkbox"
                   id="sort-by-products"
                   v-model="sortByProducts"
                   class="align-self-center" />
            <label for="sort-by-products"
                   class="text-xs align-self-center mb-0 ml-1">
              เรียงตามรายการสินค้า
            </label>
          </div>

          <!-- print selector -->
          <div class="d-flex flex-wrap printerFormatContainer mt-4">
            <div class="printerFormat"
                 v-for="(prtFr, index) in printLabels.paperSizes[selectedPaperSize]"
                 :key="index"
                 @click="printLabel(prtFr.size, prtFr.printer)">
              <div>
                <div class="d-flex">
                  <img :src="prtFr.image"
                       class="icon align-self-start" />
                  <div class="d-flex flex-column align-self-center">
                    <p class="mb-0 --text-vsm">{{ prtFr.name }}</p>
                    <p class="--text-vsm text-color-5 mt-n2"
                       v-if="prtFr.sizeToShow">
                      {{ prtFr.sizeToShow }}
                    </p>
                  </div>
                </div>

                <p class="--text-vvsm text-color-5">{{ prtFr.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>

    <!-- Change Status Modal -->
    <Modal id="orders-status-modal"
           custom_dialog_class="modal-dialog-centered">
      <template slot="body">
        <div>
          <p class="lead text-center">
            {{ $t("shipment.shipment_status.title") }}
          </p>

          <div class="alert alert-success mt-4"
               v-if="selectedOrdersDict">
            <p v-for="(statusData, status) in selectedOrdersDict"
               :key="status">
              {{ status }}: {{ statusData.count }}
            </p>
          </div>
          <div v-else
               class="alert alert-danger mt-4">
            {{ $t("shipment.shipment_status.select_pending_message") }}
          </div>

          <div class="d-flex justify-content-center mt-4">
            <Button id="btn-change-status-forward"
                    variant="primary"
                    size="sm"
                    @click="changeStatus()"
                    :loading="statusUpdateLoading"
                    :disabled="!selectedOrdersDict">{{
                      $t("shipment.shipment_status.change_status_button_text")
                    }}</Button>
          </div>
        </div>
      </template>
    </Modal>

    <!-- Return Processing Modal -->
    <Modal id="return-processing-modal"
           custom_dialog_class="modal-dialog-centered">
      <template slot="body">
        <div>
          <p class="lead text-center">{{ $t("return_processing.button") }}</p>

          <ValidationObserver v-slot="{ invalid }">
            <TextBox type="text"
                     id="trackingNo"
                     :label="$t('return_processing.enter_tracking_number')"
                     v-model="returnProcessingForm.trackingNo"
                     rules="required" />

            <p class="lead mb-2">
              {{ $t("return_processing.reason_for_return") }}
            </p>

            <validation-provider rules="required">
              <input type="radio"
                     id="shop"
                     value="shop"
                     v-model="returnProcessingForm.reason"
                     class="mr-1" />
              <label for="shop"
                     class="mr-2">
                {{ $t("return_processing.shipped_by_mistake") }}
                <i class="fas fa-store"></i>
              </label>
              <br />
              <input type="radio"
                     id="customer"
                     value="customer"
                     v-model="returnProcessingForm.reason"
                     class="mr-1" />
              <label for="customer"
                     class="mr-2">
                {{ $t("return_processing.customer_return") }}
                <i class="fas fa-user"></i>
              </label>
            </validation-provider>

            <div class="d-flex justify-content-center mt-4">
              <Button id="btn-change-status-forward"
                      variant="primary"
                      size="sm"
                      @click="handleReturnProcessingSubmit()"
                      :loading="returnProcessingLoading"
                      :disabled="returnProcessingLoading || invalid">{{ $t("general.submit")
                      }}</Button>
            </div>
          </ValidationObserver>
        </div>
      </template>
    </Modal>

    <!-- COD filter modal -->
    <Modal id="cod-filter-modal"
           backdrop>
      <template slot="header">
        <h5 class="modal-title pt-0">กรองจากยอด COD</h5>
      </template>
      <template slot="body">
        <div class="d-flex">
          <NumberField type="number"
                       id="codFilterMin"
                       v-model="codFilterForm.min"
                       label="จาก" />
          <div class="m-2"></div>
          <NumberField type="number"
                       id="codFilterMax"
                       v-model="codFilterForm.max"
                       label="ถึง" />
        </div>
      </template>
    </Modal>

  </div>
</template>

<script>
import TextBox from "@/components/core/TextBox";
import NumberField from "@/components/core/TextBox";
import DataTable from "@/components/core/DataTable";
import Modal from "@/components/core/Modal";
import Button from "@/components/core/Button";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { Alert } from "@/helpers/alert";
import Spinner from "@/components/core/Spinner";
import Tabs from "@/components/core/Tabs";
import DropZone from "@/components/derived/DropZone";
import CopyButton from "@/components/derived/CopyButton";
import _ from "lodash";
import { mapGetters } from "vuex";
import { getOrderStatus } from "@/helpers/core";
import { i18n } from "@/i18n";
import { printFormats } from "@/helpers/print";
import Select from "@/components/core/Select";
import { FILTERS } from "@/helpers/filters";
import { Toast, ToastFix } from "@/helpers/toastr";
import axios from "axios";
import { printLabels } from "@/helpers/print";
import { printHtmlLabels } from "@/helpers/htmlPrinter";

export default {
  name: "OrdersTableEs",
  components: {
    TextBox,
    NumberField,
    DataTable,
    Modal,
    vueDropzone: vue2Dropzone,
    Button,
    Spinner,
    Tabs,
    DropZone,
    Select,
    CopyButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    create_path_name: {
      type: String,
    },
    detail_path_name: {
      type: String,
    },
    edit_path_name: {
      type: String,
    },
    color_scheme: {
      type: String,
    },
    sample_file_link: {
      type: String,
    },
    sales_agent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      orderRows: null,
      orderCounts: null,
      LastEvaluatedKey: null,
      paginatedOrders: null,

      loading: false,
      miniLoading: false,
      modalLoading: false,
      statusUpdateLoading: false,
      importLoading: false,
      returnProcessingLoading: false,

      allowEditStatus: [1, 2, 7],
      allowCancelStatus: [1, 2],
      selectedOrders: [],
      selectedStatus: null,

      manualCronLoading: false,
      manualCronLoadingStatus: false,

      page: 1,
      perPage: 100,
      lastPage: null,
      selectAllStatus: false,
      currentTabStatus: 0,
      printFormats: null,
      printUrl: null,
      showOnlyErrorOrders: false,

      printLabelLoading: false,
      printLabelLoadingProgress: 0,
      printLabelLoadingTimer: null,
      printLabels: null,
      selectedPaperSize: localStorage.getItem("paperSize"),

      shops: null,
      selectedShop: null,
      sampleFileLink: null,
      sortByProducts: false,
      codFilterForm: { min: null, max: null },

      search: {
        column: "trackingNo",
        Keyword: null,
      },

      returnProcessingForm: {
        trackingNo: null,
        reason: null,
      },

      // filter by shop
      orderShops: null,
      selectedOrderShop: null,
    };
  },

  created() {
    this.printLabels = printLabels();

    this.orderCounts = [
      { label: i18n.t("tabs.all"), value: 0 },
      { label: i18n.t("tabs.pending"), value: 1 },
      { label: i18n.t("tabs.to_ship"), value: 2 },
      { label: i18n.t("tabs.shipped"), value: 3 },
      { label: i18n.t("tabs.error"), value: 6 },
      { label: i18n.t("tabs.delivered"), value: 4 },
      { label: i18n.t("status.return_to_sender"), value: 8 },
      { label: i18n.t("tabs.cancelled"), value: 5 },
    ];

    // retrieve selected tab from storage
    this.currentTabStatus = parseInt(localStorage.getItem("currentTabStatus"));

    //fetch shops
    this.fetchShops();

    // determines file sample to download
    setTimeout(() => this.fetchBoxSizes(), 1000);
  },

  mounted() {
    document.addEventListener("print-success", (data) => {
      this.printUrl = data.detail;
      this.printLabelLoading = false;
    });
  },

  methods: {

    handleCodFilterTriggerBtnClick() {
      $("#cod-filter-modal").modal("show");
    },

    handleBtnCodFilterApplyClick() {
      $("#cod-filter-modal").modal("hide");
    },

    handleShopFilterChange() {
      for (let i = 0; i < this.selectedOrders.length; i++) {
        this.toggleSelect(this.selectedOrders[i])
      }
      this.handlePageChange()
    },

    runManualCron(status) {
      this.manualCronLoading = true;
      this.manualCronLoadingStatus = status;
      this.$store.dispatch("orders/submitManualCron", {
        data: { orderStatus: status },
        callback: (status, data) => {
          if (status) Toast("ระบบกำลังปรับสถานะการจัดส่ง", "success");
          this.manualCronLoading = false;
          this.manualCronLoadingStatus = null;
        },
      });
    },

    setPaperSize() {
      localStorage.setItem("paperSize", this.selectedPaperSize);
      this.printUrl = null;
    },

    handleReturnProcessingSubmit() {
      this.returnProcessingLoading = true;
      this.$store.dispatch("orders/submitReturnProcessing", {
        data: this.returnProcessingForm,
        callback: (status, data) => {
          if (status) {
            ToastFix(this.returnProcessingForm.trackingNo, "success");
            this.returnProcessingForm.trackingNo = null;
            this.returnProcessingForm.reason = null;
            this.handleTabChange(this.currentTabStatus);
          }
          this.returnProcessingLoading = false;
        },
      });
    },

    tdHtml(type, value) {
      const types = {
        status: (value) => {
          let statusData = getOrderStatus(value);
          if (!statusData) return value;
          return `<span class="badge badge-pill text-uppercase status-badge --${statusData.variation}">${statusData.title}</span>`;
        },

        createdAtDate: (value) => {
          return FILTERS.formatDate(value);
        },

        createdAtTime: (value) => {
          return FILTERS.formatTime(value);
        },

        ellipsis: (value) => {
          return FILTERS.ellipsis(value, 15);
        },
      };
      return types[type] ? types[type](value) : value;
    },

    paginate(forward = true) {
      if (forward) {
        this.page++;
      } else {
        this.page = Math.max(--this.page, 1);
      }

      this.fetchOrders();
    },

    fetchOrders(callback) {
      this.loading = true;
      this.$store.dispatch("orders/fetchSalesAgentOrdersEs", {
        status: this.currentTabStatus,
        perPage: this.perPage,
        page: this.page,
        callback: (status, data) => {
          this.orderRows = data.orders.data;
          this.orderCounts = [
            {
              label: i18n.t("tabs.all"),
              number: data.orderCounts[0],
              value: 0,
            },
            {
              label: i18n.t("tabs.pending"),
              number: data.orderCounts[1],
              value: 1,
            },
            {
              label: i18n.t("tabs.to_ship"),
              number: data.orderCounts[2],
              value: 2,
            },
            {
              label: i18n.t("tabs.shipped"),
              number: data.orderCounts[3],
              value: 3,
            },
            {
              label: i18n.t("tabs.error"),
              number: data.orderCounts[6],
              value: 6,
            },
            {
              label: i18n.t("tabs.delivered"),
              number: data.orderCounts[4],
              value: 4,
            },
            {
              label: i18n.t("status.return_to_sender"),
              number: data.orderCounts[8],
              value: 8,
            },
            {
              label: i18n.t("tabs.cancelled"),
              number: data.orderCounts[5],
              value: 5,
            },
          ];
          this.lastPage = data.orders.last_page;
          this.loading = false;
          this.handlePageChange();
          if (callback) callback();
        },
      });
    },

    copyHandler(type) {
      let message = "Copied to Clipboard";
      if (type === "danger") message = "Error copying";
      Toast(message, type);
    },

    searchOrders() {
      let searchData = {
        ...this.search,
        status: this.currentTabStatus,
        salesAgent: this.sales_agent,
      };
      this.loading = true;
      // this.handleTabChange(0);
      this.$store.dispatch("orders/searchOrders", {
        ...searchData,
        callback: (status, data) => {
          this.orderRows = data.results.map((d) => {
            return {
              id: d.id,
              trackingNo: d.trackingNo,
              status: d.status,
              shippingMethod: d.shippingMethod,
              orderCode: d.orderCode,
              customerName: d.customerName,
              phoneNo: d.phoneNo,
              user: d.user,
              createdAt: d.createdAt,
              codAmount: d.codAmount,
              codPaid: d.codPaid,
              printed: d.printCount && d.printCount > 0,
            };
          });
          console.log(this.orderRows);
          this.page = 1;
          this.lastPage = null;
          this.LastEvaluatedKey = null;
          this.handlePageChange();
          this.loading = false;
        },
      });
    },

    showOnlyErrorShipments() {
      this.showOnlyErrorOrders = true;
      this.handleTabChange(this.currentTabStatus);
    },

    handlePageChange() {
      // let startIndex = (this.page - 1) * this.perPage;
      // let endIndex = this.perPage * this.page - 1;

      this.paginatedOrders = this.orderRows;

      this.selectAllStatus = false;
      this.selectedOrders = [];

      // shops filter (unique by id)
      let shopsDict = {};
      this.orderShops = [];
      this.paginatedOrders.forEach((o) => (shopsDict[o.shopId] = o.shopName));
      Object.keys(shopsDict).forEach((shopId) =>
        this.orderShops.push({ id: shopId, name: shopsDict[shopId] })
      );
      this.orderShops.unshift({ id: null, name: "ทั้งหมด" });
    },

    handlePerPageChange() {
      this.page = 1;
      this.fetchOrders();
    },

    selectAll(notPrinted = false) {
      if (this.selectAllStatus) {
        $("tr.--selected").removeClass("--selected");
        $(".table-checkbox").prop("checked", false);
        this.selectedOrders = [];
      } else {
        let ordersToSelect = notPrinted
          ? this.paginatedOrders.filter((o) => !o.printCount)
          : this.paginatedOrders;
        ordersToSelect.forEach((order) => this.toggleSelect(order));
      }
      this.selectAllStatus = !this.selectAllStatus;
    },

    toggleSelect(order, manual = false) {
      let checkboxElem = this.$refs["order-checkbox-" + order.id][0];
      if (!checkboxElem) return;
      if (manual) checkboxElem.checked = !checkboxElem.checked;
      let tableRow = checkboxElem.parentElement.parentElement;
      if (!tableRow) return;
      if (!checkboxElem.checked) {
        checkboxElem.checked = true;
        tableRow.classList.add("--selected");
        this.selectedOrders.push({
          id: order.id,
          status: order.status,
          createdAt: order.createdAt,
        });
      } else {
        let index = this.selectedOrders.findIndex((a) => a.id === order.id);
        this.selectedOrders.splice(index, 1);
        checkboxElem.checked = false;
        tableRow.classList.remove("--selected");
      }
    },

    handleTabChange(value) {
      this.currentTabStatus = value;
      localStorage.setItem("currentTabStatus", value);
      this.page = 1;

      this.fetchOrders(() => {
        //clear configs on tabs change
        this.selectAllStatus = false;
        this.selectedOrders = [];
        this.search.keyword = null;
        this.showOnlyErrorOrders = false;
      });
    },

    goto(type, id, newTab = false) {
      let routerData;

      if (type === "Show")
        routerData = { name: this.detail_path_name, params: { id } };
      else if (type === "Edit")
        routerData = { name: this.edit_path_name, params: { id } };
      else if (type === "TaxInvoice") {
        routerData = { name: "Seller-Order-Tax-Invoice", params: { id } };
      }

      if (newTab) window.open(this.$router.resolve(routerData).href, "_blank");
      else this.$router.push(routerData);
    },

    deleteItem(id) {
      Alert(
        i18n.t("general.cancel"),
        i18n.t("alert.cancel.message"),
        null,
        () => {
          this.loading = true;
          this.$store.dispatch("orders/deleteOrder", {
            id: id,
            callback: (status) => {
              if (status) this.$emit("deleted", id);
              this.loading = false;
            },
          });
        }
      );
    },

    importOrders(value) {
      var url = value[0];
      this.$emit("imported", { url, selectedShop: this.selectedShop });

      //show loading message
      this.importLoading = true;
      setTimeout(() => {
        $("#orders-import-modal").modal("hide");
        this.importLoading = false;
      }, 2000);
    },

    async printLabel(size, printer) {
      this.printLabelLoading = true;
      this.printUrl = null;
      this.printLabelLoadingProgress = 0;

      let orders;
      if (this.sortByProducts) {
        // sort orders by product sku alphabetically ========================
        // 1. build product-sku array
        let PRODUCTS = [];
        this.selectedOrders.forEach((o) => {
          if (o.products)
            for (const pCode of Object.keys(o.products)) PRODUCTS.push(pCode);
        });
        PRODUCTS = [...new Set(PRODUCTS)].sort();

        // 2. specify print-rank according to dict
        orders = this.selectedOrders.map((o) => {
          o.printRank = 1;
          if (o.products) {
            let pCodes = Object.keys(o.products);
            if (pCodes && pCodes.length) {
              let firstPCode = pCodes[0];
              o.printRank = Math.max(PRODUCTS.indexOf(firstPCode), 1);
            }
          }
          return o;
        });

        // 3. sort by print-rank
        orders.sort((a, b) => b.printRank - a.printRank);
        // ========================================================
      } else orders = this.selectedOrders;


      if (printer === "proship_v1_html") {
        let orders = []

        const apiRequests = []
        for (const _order of this.selectedOrders)
          apiRequests.push(this.$store.dispatch("orders/fetchOrderDetailAsync", { id: _order.id }))

        const responses = await Promise.all(apiRequests)

        const ordersForUpdate = [];
        for (const resp of responses) {
          const { status, data } = resp;
          if (!status) continue;

          let order = JSON.parse(JSON.stringify(data));

          // only update count first time
          if (order && order.details && !order.details.printed) ordersForUpdate.push(order);

          for (const key in order.details) order[key] = order.details[key];
          orders.push(order);

          this.printLabelLoadingProgress = Math.min((orders.length / this.selectedOrders.length) * 100, 98);
        }

        // update print count
        await this.$store.dispatch("orders/updateOrderAsync", { data: { orders: ordersForUpdate } });

        // for (const order of ordersForUpdate) {
        //   if (order && order.details && !order.details.printed) {
        //     apiRequests.push(this.$store.dispatch("orders/updateOrderAsync", { id: `${order.id}__${order.createdAt}`, order: order.details }))
        //   }
        // }
        // await Promise.all(updateApiRequests)

        // print
        await printHtmlLabels(orders, size);
        this.printLabelLoading = false;
        this.printLabelLoadingProgress = 100;
        return;
      }

      this.$store.dispatch("orders/printLabels", {
        data: {
          orders: orders.map((o) => o.id + "|" + o.createdAt),
          size,
          printer,
        },
        callback: (status, data) => {
          if (status) {
            if (data.mode == "api") {
              this.printUrl = data.url;
              this.printLabelLoading = false;
              this.printLabelLoadingProgress = 100;
            } else
              document
                .querySelector("#orders-print-labels")
                .setAttribute("data-backdrop", "static");
          } else {
            this.printLabelLoading = false;
          }
        },
      });

      this.printLabelLoadingProgress = 0;
      clearInterval(this.printLabelLoadingTimer);

      // increase to first 50% faster
      this.printLabelLoadingTimer = setInterval(() => {
        if (this.printLabelLoadingProgress <= 50)
          this.printLabelLoadingProgress += 10;
        // increase it by 1 each 500ms
        else this.printLabelLoadingProgress += 1;

        this.printLabelLoadingProgress = Math.min(98, this.printLabelLoadingProgress);
      }, 500);
    },

    addPrintIcon() {
      // add print icon on frontend
      for (let i = 0; i < this.selectedOrders.length; i++) {
        const orderId = this.selectedOrders[i].id;
        let checkBox = document.getElementById("order-checkbox-" + orderId);
        if (!checkBox.parentNode.querySelector("i")) {
          let printIcon = document.createElement("i");
          printIcon.classList.add("fal", "fa-print", "text-success", "mr-2");
          checkBox.parentNode.appendChild(printIcon);
        }
      }
    },

    changeStatus(forward) {
      this.statusUpdateLoading = true;

      let _selectedOrders = this.selectedOrders
        .filter((o) => o.status == 1)
        .map((o) => {
          return {
            ...o,
            status: 2,
          };
        });

      this.$store.dispatch("orders/massUpdateOrderStatus", {
        data: { orders: _selectedOrders },
        callback: (status, data) => {
          if (status) {
            if (status) {
              this.handleTabChange(this.currentTabStatus);
              $("#orders-status-modal").modal("hide");
              ToastFix(data.message, "info");
            }
          }
          this.statusUpdateLoading = false;
        },
      });
    },

    cancelModal(id) {
      $("#" + id).modal("hide");
    },

    openModal(id) {
      $("#" + id).modal("show");
    },

    fetchShops() {
      this.shops = null;
      this.$store.dispatch("shops/fetchShops", {
        callback: (status, data) => {
          if (status) {
            this.shops = data.map((s) => {
              return {
                name: s.details.name,
                value: s.id,
              };
            });
            this.shops.unshift({ name: "None", value: null });
            this.selectedShop = this.shops[0].id;
          }
        },
      });
    },

    fetchBoxSizes(callback) {
      if (this.generalSettings.thaiPostUserName) {
        this.$store.dispatch("orders/fetchBoxSizes", {
          username: this.generalSettings.thaiPostUserName,
          callback: (status, data) => {
            if (status) {
              let boxSizeSample =
                "https://proship-dev.s3-ap-southeast-1.amazonaws.com/Import-Standard-Format-BoxSize+(1).xlsx";
              let normalSample =
                "https://proship-dev.s3-ap-southeast-1.amazonaws.com/Import%2BStandard%2BFormat_(5).xlsx";
              if (data && data.length) this.sampleFileLink = boxSizeSample;
              else this.sampleFileLink = normalSample;
            }
            if (callback) callback();
          },
        });
      }
    },
  },

  computed: {
    ...mapGetters("orders", ["activeStatus"]),
    ...mapGetters("user", ["authData"]),
    ...mapGetters({ generalSettings: "settings/generalSettings" }),

    username() {
      if (this.authData && this.authData.user && this.authData.user.username)
        return this.authData.user.username;
      return false;
    },

    buttonClass() {
      return `btn btn-sm btn-outline-${this.color_scheme} mr-2`;
    },

    selectedOrdersDict() {
      if (this.selectedOrders && this.selectedOrders.length) {
        let _selectedOrders = this.selectedOrders.filter((o) => o.status == 1);

        let obj = {};
        _selectedOrders.forEach((ord) => {
          let status =
            getOrderStatus(ord.status).title +
            " to " +
            (ord.status == 1
              ? getOrderStatus(2).title
              : getOrderStatus(1).title);
          if (obj[status]) obj[status].count++;
          else obj[status] = { count: 1 };
        });
        return obj;
      }
    },

    computedOrdersList() {
      let orders = this.paginatedOrders;

      if (this.selectedOrderShop)
        orders = orders.filter((o) => o.shopId == this.selectedOrderShop);

      let min = parseFloat(this.codFilterForm.min) || 0;
      let max = parseFloat(this.codFilterForm.max) || Infinity;
      return orders.filter((o) =>
        o.codAmount ? o.codAmount >= min && o.codAmount <= max : true
      );

      return orders
    },

    activetabIndex() {
      return this.currentTabStatus ? this.currentTabStatus : 0;
    },

    showSelectAll() {
      return (
        (this.currentTabStatus && this.currentTabStatus === 1) ||
        this.currentTabStatus === 2
      );
    },
  },

  watch: {},
};
</script>

<style lang="scss" scoped>
.shipping-label-format {
  text-align: center;
  padding: 0.5rem;
  background-color: rgba($brand-blue-light, 0.1);
  border-radius: toRem(10px);
  margin: 0.25rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    height: toRem(100px);
    width: auto;
  }

  span {
    margin-top: 0.25rem;
    font-size: 0.8rem;
  }
}

.btn-outline-primary {
  border: toRem(1.5px) solid;
}

.printerFormatContainer {
  width: 210px;

  @include for-larger-than-phone {
    width: 420px;
  }

  margin: 0 auto;
  overflow: auto;

  .printerFormat {
    width: 200px;
    background-color: rgb(252, 252, 252);
    padding: 0.25rem;
    border-radius: 3px;
    margin: 0.25rem;
    cursor: pointer;

    &:hover {
      background-color: whitesmoke;
    }

    .icon {
      height: 1rem;
      width: auto;
      margin-right: 0.5rem;
      margin-top: 0.4rem;
    }
  }
}
</style>