import { http } from "@/helpers/http";

const baseURL = 'https://lqyaow712a.execute-api.ap-southeast-1.amazonaws.com/dev/v1';
// const baseURL = 'http://localhost:3000/dev/v1';

export default {
  namespaced: true,

  actions: {

    fetchNotifications({ state, commit, rootState }, payload) {
      http.get(baseURL + "/", payload.data)
        .then(response => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch(error => {
          if (payload.callback) payload.callback(false);
        })
    },

    readNotificaiton({ state, commit, rootState }, payload) {
      http.get(baseURL + "/" + payload.id + "/read")
        .then(response => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch(error => {
          if (payload.callback) payload.callback(false);
        })
    }
  },
};
