import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { i18n } from "../i18n";

export const applyValidators = function () {

    Vue.component('ValidationProvider', ValidationProvider);
    Vue.component('ValidationObserver', ValidationObserver);

    extend('required', {
        ...required,
        message: i18n.t("validation.field_required")  // 'This field is required'
    });
    extend('email', {
        ...email,
        message: 'This field must be a valid email'
    });
    extend('digit', {
        ...email,
        message: i18n.t("validation.must_be_digit")
    });

    extend('phone', (value) => {
        if (value[0] !== "0") return i18n.t("validation.must_start_with_zero");

        if (value.length == 9) {
            let regex = /^(02|032|034|035|036|037|038|039|042|043|044|045|052|053|054|055|056|073|074|075|076|077)(\d)*$/
            return regex.test(value) ? true : "Invalid"
        }
        else if (value.length == 10) {
            let regex = /^(060|061|062|063|064|065|066|067|068|069|080|081|082|083|084|085|086|087|088|089|090|091|092|093|094|095|096|097|098|099)(\d)*$/
            return regex.test(value) ? true : "Invalid"
        }
        else if (value.length < 9) return "หมายเลขโทรศัพท์จะต้องมี 9/10 หลัก";
        else if(value.length > 10) return "Invalid";
        return true;
    });

    extend('vat', (value) => {
        let val = String(value);
        if (val.length != 13) return "Must have 13 digits";
        return true;
    });

    extend('promptpayId', (value) => {
        let val = String(value);
        if (val.length != 13 && val.length != 10) return "Must have 10 or 13 digits";
        if (value[0] !== "0") return i18n.t("validation.must_start_with_zero");
        return true;
    });

    extend('wallletId', (value) => {
        if (value.data && value.error) return value.error;
        return true;
    });

    extend('zipcode', (value) => {
        if (!value) return "Invalid";
        value = value + "";
        if (value.length < 5) return i18n.t("validation.zipcode_5_digits");
        value = parseInt(value);
        const MIN = 10100;
        const MAX = 96220;
        return (value >= MIN && value <= MAX) ? true : i18n.t("validation.invalid_zipcode");
    });

    extend('address', (value) => {
        if (!value) return "Invalid value";

        //only zipcode is valid - mark as valid
        if (value.zipcode) return true;

        if (!value.address) return "Invalid address";
        if (!value.province) return "Invalid province";
        if (!value.district) return "Invalid district";
        if (!value.subDistrict) return "Invalid sub-district";
        if (!value.zipcode) return i18n.t("validation.invalid_zipcode");
        return true;
    });
}
