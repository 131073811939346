<template>
  <div class="form-group">
    <TextBox
      :derived="true"
      type="Number"
      :maxval="10"
      :placeholder="placeholder"
      :id="id"
      :value="value"
      :label="label"
      @input="watchChanges()"
      v-model="walletId.data"
      :rules="rules"
      input_wrapper_class="pb-0"
    />
    <span class="text-info" v-if="validating">
      <i class="fal fa-sync-alt fa-spin"></i>
    </span>
    <label class="text-danger --text-vsm" v-if="walletId.error">
      {{ walletId.error }}
    </label>
  </div>
</template>

<script>
import axios from "axios";
import TextBox from "@/components/core/TextBox";
import _ from "lodash";

export default {
  name: "WalletId",
  components: {
    TextBox,
  },
  props: {
    label: {
      type: String,
    },
    value: {
      type: null,
    },
    placeholder: {
      type: String,
    },
    id: {
      type: String,
    },
    rules: {
      type: String,
      default: "",
    },
  },

  async created() {
    this.walletId = this.value || { data: null, error: null };
    if (this.value && this.value.data) await this.emitUpdate();
  },

  data() {
    return {
      walletId: null,
      error: null,
      validating: false,
      validateTimer: null,
    };
  },

  methods: {
    watchChanges() {
      clearTimeout(this.validateTimer);
      this.validateTimer = setTimeout(async () => {
        await this.emitUpdate();
        clearTimeout(this.validateTimer);
      }, 500);
    },

    async emitUpdate() {
      if (!this.walletId.data || !this.walletId.data.length) {
        this.walletId.error = null;
        this.$emit("input", this.walletId);
        return;
      }

      this.validating = true;
      this.error = null;
      let url = "https://jobs.proship.co.th/validate-walletid";
      // let url = "http://localhost:5000/validate-walletid";
      let resp = await axios.post(
        url,
        { walletId: this.walletId.data },
        {
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoicHJvc2hpcC1hZG1pbiIsImlhdCI6MTYwNTc3MzU2Mn0.0r6TemJYJGQ-fsdKJndNtdVGtL1n97O3U6MGAxGLXag",
          },
        }
      );
      resp = resp.data;
      if (resp.responseCode === "failed") {
        // this.error = resp.responseDescription;

        if(resp.responseDescription === "à¹à¸¡à¹à¸à¸à¸£à¸²à¸¢à¸à¸²à¸£à¸à¸±à¸à¸à¸µ Wallet@POST à¸à¸²à¸¡à¹à¸à¸­à¸£à¹à¹à¸à¸£à¸¨à¸±à¸à¸à¹à¸à¸µà¹ à¸à¸£à¸¸à¸à¸²à¸à¸£à¸§à¸à¸ªà¸­à¸à¸à¹à¸­à¸¡à¸¹à¸¥à¸à¸±à¸à¸¥à¸¹à¸à¸à¹à¸²à¸­à¸µà¸à¸à¸£à¸±à¹à¸") resp.responseDescription = "ไม่พบรายการบัญชี Wallet@POST ตามเบอร์โทรศัพท์นี้ กรุณาตรวจสอบ ข้อมูลอีกครั้ง";

        this.walletId.error = resp.responseDescription;
        // this.$emit("input", this.walletId);
      } else {
        this.walletId.error = null;
      }
      this.$emit("input", this.walletId);
      this.validating = false;
    },
  },

  watch: {
    value: async function (newVal, oldVal) {
      if (newVal && !_.isEqual(newVal, oldVal)) {
        this.walletId = newVal;
        if (newVal.data) await this.emitUpdate();
      } else {
        this.walletId = { data: newVal ? newVal.data : null, error: null };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>