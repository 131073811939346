<template>
  <div>
    <ValidationProvider :rules="rules">
      <div :class="'shipping-methods d-flex flex-wrap justify-content-' + justify + ' ' + wrapper_class"
           :id="id">
        <div v-for="(sm, index) in options"
             :key="index"
             class="shipping-method"
             :class="smClass(sm)"
             @click="toggle(sm)">
          <img :src="sm.src"
               class="img-fluid" />
          <label>{{ sm.name }}</label>
          <div class="shipping-method-selected"
               v-if="checkIfSelected(sm.name)">
            <img src="@/assets/circle_outline.svg"
                 alt
                 height="15px"
                 width="15px" />
          </div>
        </div>
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import { shippingInfo } from "@/helpers/core";
import _ from "lodash";

export default {
  name: "ShippingMethodInput",
  props: {
    id: {
      type: String
    },
    options: {
      type: Array,
      default: () => {
        return shippingInfo("all");
      }
    },
    values: {
      type: Array
    },
    variant: {
      type: String,
      default: "primary"
    },
    size: {
      type: String,
      default: "lg"
    },
    wrapper_class: {
      type: String
    },
    rules: {
      type: String,
      default: ""
    },
    multiple: {
      type: Boolean,
      default: true
    },
    justify: {
      type: String,
      default: "center"
    },
    disable: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selectedMethods: null
    };
  },

  created() {
    this.selectedMethods = this.values.map(val => val.toLowerCase()) || [];
    this.emitUpdate();
  },

  methods: {
    toggle(sm) {
      if (this.disable) return;

      if (!sm.available) return;
      let name = sm.name.toLowerCase();
      if (!this.multiple) {
        this.selectedMethods = [name];
      } else {
        let index = this.selectedMethods.findIndex(sm => sm === name);
        if (index == -1) this.selectedMethods.push(name);
        else this.selectedMethods.splice(index, 1);
      }

      this.emitUpdate();
    },

    checkIfSelected(name) {
      if(!name) return false;
      name = name.toLowerCase();
      return this.selectedMethods && this.selectedMethods.includes(name);
    },

    emitUpdate() {
      this.$emit(
        "input",
        this.selectedMethods.length > 0 ? this.selectedMethods : null
      );
    },

    smClass(sm) {
      let css = {
        "--disabled": !sm.available,
        "--active": this.checkIfSelected(sm.name)
      };
      css["--" + this.variant] = true;
      css["--" + this.size] = true;
      return css;
    }
  },

  watch: {
    values: function (newValues, oldValues) {
      if (newValues && typeof newValues == "object" && !_.isEqual(newValues, oldValues)) {
        this.selectedMethods = newValues.map(val => val.toLowerCase());
        this.emitUpdate();
      }
    }
  }
};
</script>

<style lang="scss"
       scoped>
      .shipping-method {
        margin: 0 toRem(12px) toRem(24px) toRem(12px);

        @include for-laptops-only {
          margin-bottom: toRem(10px);
        }

        padding: toRem(20px) toRem(20px);
        border-radius: toRem(10px);
        background-color: $dashboard-background;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        width: 120px;
        height: 75px;
        text-align: center;
        position: relative;

        .shipping-method-selected {
          position: absolute;
          bottom: 3px;
          left: 3px;
        }

        label {
          display: none;
        }

        img {
          margin-top: 1rem;
        }

        &.--sm {
          margin: 0.15rem 0.25rem;
          padding: 0.25rem 0.5rem;
          width: toRem(100px);
          height: toRem(75px);
        }

        &.--sh {
          margin: 0.15rem 0.25rem;
          padding: 0.25rem 0.5rem;
          width: toRem(100px);
          height: toRem(50px);

          img {
            margin-top: 0.5rem;
          }
        }

        &.--xs {
          width: toRem(25px);
          height: toRem(10px);
          margin: 0.05rem 0.05rem;
          padding: 0.15rem 0.25rem;

          img {
            display: none;
          }

          label {
            display: block;
          }

          width: toRem(100px);
          height: toRem(75px);
        }

        &.--disabled {
          display: none;
          // @include filter(grayscale(100%));
        }
      }
    </style>